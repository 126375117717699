<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="入库单ID" >
                <el-input v-model="search.ruKuDanID" size="small" />
            </x-search-item>
            <x-search-item label="入库类型" >
                <el-input v-model="search.ruKuLX" size="small" />
            </x-search-item>
            <x-search-item label="入库时间" textWidth="238px" hasEnd>
                <el-date-picker v-model="search.ruKuSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.ruKuSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column  prop="ruKuDanID" label="入库单ID" />
            <el-table-column  prop="ruKuShangPinZongShu" label="入库商品总数" />
            <el-table-column  prop="ruKuLX" label="入库类型" />
           <!-- <el-table-column  prop="ruKuJinE" label="入库金额" />-->
            <el-table-column prop="ruKuJinE" label="应收金额" show-overflow-tooltip/>
            <el-table-column  prop="shiShouShangPinZongShu" label="实收商品总数" />
            <el-table-column  prop="shiShouJinE" label="实收金额" />
            <el-table-column  prop="caoZuoRen" label="操作人" />
            <el-table-column  prop="caoZuoRenID" label="操作人ID" />
            <el-table-column  prop="beiZhu" label="备注" />
            <el-table-column  prop="ruKuSJ" label="入库时间" />
            <el-table-column  align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/hlgl/RuKuXX";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/hlgl/rkd/RuKuXXDetail";

    export default {
        name: "RuKuXXQuery",
        mixins: [XTableBase],
        components: {Detail,query},
        data() {
            this.refreshService = query;
            return {
                search: {
                    ruKuDanID: '',
                    ruKuLX: '',
                    ruKuSJBegin: '',
                    ruKuSJEnd: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>